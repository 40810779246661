<template>
  <v-dialog v-model="credentialsDialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="subtitle-2 mr-6"
        color="primary"
        depressed
        v-bind="attrs"
        v-on="on"
      >
        {{ buttonTitle }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Credentials</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <h6>Current Settings</h6>
          <v-row no-gutters>
            <v-col cols="12">
              <p>
                <v-chip
                  v-if="!!user.email"
                  class="ma-2"
                  :input-value="user.email_verified"
                  filter
                  label
                  :color="user.email_verified ? 'success' : 'warning'"
                >
                  Email: {{ user.email }}&nbsp; ({{
                    user.email_verified ? "Verified" : "Not Verified"
                  }})</v-chip
                >
                <br />
                <v-chip
                  v-if="!!user.phone_number"
                  class="ma-2"
                  :input-value="user.phone_number_verified"
                  filter
                  label
                  :color="user.phone_number_verified ? 'success' : 'warning'"
                >
                  Mobile: {{ user.phone_number }}&nbsp;({{
                    user.phone_number_verified ? "Verified" : "Not Verified"
                  }})</v-chip
                >
              </p>
            </v-col>
          </v-row>

          <v-expansion-panels v-model="panelIdx">
            <v-expansion-panel
              :disabled="btnState && panelIdx !== 0"
              :readonly="btnState && panelIdx == 0"
            >
              <v-expansion-panel-header>
                <h4>Update Email</h4>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row no-gutters>
                  <v-col cols="6">
                    <v-text-field
                      :disabled="isToken"
                      label="Password*"
                      type="password"
                      v-model="model.old_password"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      :disabled="isToken"
                      v-model="model.email"
                      label="Email"
                      type="email"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-if="isToken && panelIdx == 0"
                      label="Verification Code"
                      type="text"
                      v-model="model.token"
                      placeholder="Enter Verification Code"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
              :disabled="btnState && panelIdx !== 1"
              :readonly="btnState && panelIdx == 1"
            >
              <v-expansion-panel-header>
                <h4>Update Mobile</h4>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row no-gutters>
                  <v-col cols="6">
                    <v-text-field
                      :disabled="isToken"
                      label="Password*"
                      type="password"
                      v-model="model.old_password"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      :disabled="isToken"
                      v-model="model.phone_number"
                      label="Mobile"
                      type="text"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-if="isToken && panelIdx == 1"
                      label="Verification Code"
                      type="text"
                      v-model="model.token"
                      placeholder="Enter Verification Code"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
              :disabled="btnState && panelIdx !== 2"
              :readonly="btnState && panelIdx == 2"
            >
              <v-expansion-panel-header>
                <h4>Update Password</h4>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row no-gutters>
                  <v-col cols="6">
                    <v-text-field
                      :disabled="isToken"
                      label="Old Password*"
                      type="password"
                      v-model="model.old_password"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field
                      label="New Password*"
                      type="password"
                      v-model="model.password"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-if="isToken && panelIdx == 2"
                      label="Verification Code"
                      type="text"
                      v-model="model.token"
                      placeholder="Enter Verification Code"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </v-card-text>
      <v-alert
        v-model="isAlert"
        :type="alertType"
        close-text="Close Alert"
        dismissible
        >{{ alertMessage }}</v-alert
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="warning"
          :disabled="loading || panelIdx == null"
          text
          @click="panelIdx = null"
        >
          Reset
        </v-btn>
        <v-btn
          color="blue darken-1"
          :disabled="loading"
          text
          @click="
            panelIdx = null;
            credentialsDialog = false;
          "
        >
          Close
        </v-btn>
        <v-btn
          :disabled="!btnState || loading"
          color="blue darken-1"
          text
          :loading="loading"
          @click="doSubmit"
        >
          {{ submitTitle }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { EventBus } from "@/event-bus";
import graphqlMixin from "../mixins/graphql-mixin";
export default {
  mixins: [graphqlMixin],
  data() {
    return {
      panelIdx: null,
      types: ["email", "mobile", "password"],
      credentialsDialog: false,
      model: {
        old_password: null,
        password: null,
        email: null,
        phone_number: null,
      },
      isToken: false,
      loading: false,
    };
  },
  props: ["buttonTitle"],

  computed: {
    identityChanged() {
      const types = this.types;
      switch (types[this.panelIdx]) {
        case "email":
          return this.model.email !== this.user.email;
        case "mobile":
          return this.model.phone_number !== this.user.phone_number;
        default:
          return false;
      }
    },
    btnState() {
      const types = this.types;
      if (!this.model.old_password || this.panelIdx == null) return false;
      switch (types[this.panelIdx]) {
        case "email":
          return (
            this.model.email !== this.user.email || !this.user.email_verified
          );
        case "mobile":
          return (
            this.model.phone_number !== this.user.phone_number ||
            !this.user.phone_number_verified
          );
        default:
          return !!this.model.password;
      }
    },
    submitTitle() {
      const types = this.types;
      if (this.isToken)
        return `Verify ${
          types[this.panelIdx] === "email" ? "Email" : "Mobile Number"
        } `;
      switch (types[this.panelIdx]) {
        case "email":
          return `${
            this.model.email === this.user.email ? "Verify" : "Change"
          } Email`;

        case "mobile":
          return `${
            this.model.phone_number === this.user.phone_number
              ? "Verify"
              : "Change"
          } Mobile`;

        default:
          return "Change Password";
      }
    },
    retValue() {
      const types = this.types;
      switch (types[this.panelIdx]) {
        case "email":
          return this.model.email;
        case "mobile":
          return this.model.phone_number;
        default:
          return this.model.password;
      }
    },
    user() {
      const { username, attributes } = this.$store.state.account.user || {};
      const { email, email_verified, phone_number, phone_number_verified } =
        attributes || {};
      return {
        username,
        email,
        email_verified,
        phone_number,
        phone_number_verified,
      };
    },
  },
  watch: {
    panelIdx: function (val) {
      console.log("panelIdx", val);
      this.doReset();
    },
  },
  mounted() {
    const $this = this;
    EventBus.$on("show-credentials", function () {
      console.log("bingo");
      $this.credentialsDialog = true;
    });
  },
  methods: {
    doReset() {
      this.isToken = false;
      this.model = {
        old_password: null,
        password: null,
        email: null,
        phone_number: null,
      };
    },
    async doSubmit() {
      console.log("hereeeeeeeeeeee");
      const {
        types,
        retValue: value,
        model: { token, old_password },
      } = this;

      const type = types[this.panelIdx];
      try {
        this.loading = true;
        if (this.isToken) {
          await this.$store.dispatch("account/verifyCredential", {
            type,
            value,
            token,
          });
        } else {
          const { identityChanged } = this;
          await this.$store.dispatch("account/changeCredential", {
            old_password,
            type,
            value,
            identityChanged,
          });
        }
        if (type === "password" || this.isToken) {
          this.$emit("input", false);
        } else {
          this.isToken = true;
        }
      } catch (err) {
        console.error(err);
        this.setGraphQlError(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.token-field {
  color: #90c143 !important; /* this will override the existing property applied */
  /* add whatever properties you want */
}
</style>