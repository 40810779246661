<template>
  <v-system-bar app color="#262626" class="px-4" dark height="58">
    <router-link class="d-flex align-center" to="/"
      >Orenda Financial Services</router-link
    >

    <v-spacer />

    <template v-if="authorized">
      <manage-credentials :buttonTitle="`Welcome ${user.username}`" />

      <v-divider class="ml-3 mr-3" vertical />
      <v-btn
        class="text-capitalize subtitle-2 mr-6"
        color="warning"
        depressed
        min-width="112"
        @click="signOut"
        >Sign Out</v-btn
      >
    </template>
    <template v-else>
      <v-btn
        class="text-capitalize subtitle-2 font-weight-bold mr-16"
        color="primary"
        elevation="24"
        :outlined="true"
        :large="true"
        light
        to="/account/sign-up"
        min-width="112"
        >Sign Up</v-btn
      >
      <v-divider class="ml-6 mr-3" vertical />
      <v-btn
        class="text-capitalize subtitle-2 font-weight-bold"
        elevation="24"
        color="white"
        light
        text
        to="/account/sign-in"
        min-width="112"
        >Sign In</v-btn
      >
    </template>
  </v-system-bar>
</template>

<script>
import accountMixin from "@/mixins/accounts.js";
import ManageCredentials from "@/components/ManageCredentials";

export default {
  name: "HomeSystemBar",
  mixins: [accountMixin],
  components: { ManageCredentials },
  data: () => ({}),
};
</script>
