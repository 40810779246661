export default {
  data: () => ({
    alertMessage: "",
    alertType: "warning",
    isAlert: false,
  }),
  watch: {
    alertMessage: function(val) {
      if (val) this.isAlert = true;
    },

    isAlert: function(val) {
      if (!val) this.alertMessage = "";
    },
  },
  methods: {
    setAlert(message) {
      this.alertType = "success";
      this.alertMessage = message;
      this.isAlert = true;
    },
    setGraphQlError(err) {
      console.log("setGraphQlError", err);
      this.alertType = "warning";
      this.alertMessage =
        "Error!--> " +
        (err.message
          ? err.message
          : err.errors.reduce(
              (message, item, i) =>
                (message += (i === 0 ? "" : "--") + item.message),
              ""
            ));
    },
  },
};
